import { Typography } from "@mui/material";
import { Stack } from "@mui/material/";

export default function Thanks() {
  return (
    <Stack direction={"row"} justifyContent="center" alignContent="center" padding={5}>
      <Typography variant="h4">Vielen Dank fürs Mitmachen!</Typography>
    </Stack>
  );
}
