import React from "react";
import TextField from "@mui/material/TextField";
import { Stack, Typography, Button, Fragment } from "@mui/material/";
import { useNavigate } from "react-router-dom";
import "react-app-polyfill/ie11";
import {isMobile} from 'react-device-detect';

export default function GreetingInput() {
  const [finderName, setFinderName] = React.useState();
  const defaultFound = new Date().toISOString().split("T")[0];
  const [found, setFound] = React.useState(defaultFound);
  const [foundLocation, setFoundLocation] = React.useState();
  const [finderLocation, setFinderLocation] = React.useState();
  const [finderGreeting, setFinderGreeting] = React.useState(
    "Hallo Maria Gehring,\r\nherzlichen Glückwunsch zum 80 sten."
  );
  const navigate = useNavigate();

  function GetTr(caption, text) {
    const regex = /(?:\r\n|\r|\n)/g;
    if (text != null && text.length > 0)
      return "<tr><td>" + caption + ": </td><td>" + text.replace(regex, "<br />") + "</td></tr>";
    else return "";
  }

  async function pushGreeting() {
    let textPlain = "<table>";
    textPlain += GetTr("Finder", finderName);
    textPlain += GetTr("Gefunden am", found);
    textPlain += GetTr("Fundort", foundLocation);
    textPlain += GetTr("Finder wohnt", finderLocation);
    textPlain += GetTr("Grüße", finderGreeting);
    textPlain += "</table>";
    let text = encodeURIComponent(textPlain);
    let url = "https://digicheckapi.salixx.de/greet-maria-gehring-80?text=";
    const response = await fetch(url + text, { mode: "cors" });
    return response;
  }

  function onClick(e) {
    pushGreeting();
    navigate("/Thanks");
  }

  function onFoundLocationFocus(e) {
    if ((foundLocation == null || foundLocation.length === 0) && isMobile) {      
      navigator.geolocation.getCurrentPosition(
        function (p) {
          if (foundLocation == null || foundLocation.length === 0) {
            setFoundLocation('Akt. Koordinaten: ' + p.coords.latitude + " " + p.coords.longitude + '\r\nOrt:');
          }
        },
        null,
        { maximumAge: 0, timeout: 10000, enableHighAccuracy: true }
      );      
    }
  }

  return (
    <>
      <Typography variant="h5" align="left" paddingBottom={1}>
        Ich möchte gratulieren:
      </Typography>
      <Stack spacing={2} style={{ width: "100%" }} paddingBottom={2}>
        <TextField
          variant="outlined"
          label="Mein Name"
          value={finderName}
          onChange={(e) => setFinderName(e.target.value)}
        />
        <TextField
          variant="outlined"
          label="Gefunden am"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={found}
          onChange={(e) => setFound(e.target.value)}
        />
        <TextField
          variant="outlined"
          label="Wo"
          multiline={true}
          rows={2}
          value={foundLocation}
          onChange={(e) => setFoundLocation(e.target.value)}
          onFocus={(e) => onFoundLocationFocus(e)}
        />
        <TextField
          variant="outlined"
          label="Ich wohne"
          multiline={true}
          rows={2}
          value={finderLocation}
          onChange={(e) => setFinderLocation(e.target.value)}
        />
        <TextField
          variant="outlined"
          label="Meine Nachricht an das Geburtstagskind"
          multiline={true}
          rows={4}
          value={finderGreeting}
          onChange={(e) => setFinderGreeting(e.target.value)}
        />
      </Stack>
      <Stack spacing={2} paddingBottom={2} alignItems="center">
        <Button variant="contained" color="primary" onClick={(e) => onClick(e)}>
          Abschicken
        </Button>
      </Stack>
    </>
  );
}
