import "./App.css";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Stack, Container, CssBaseline, Typography } from "@mui/material/";
import GreetingInput from "./GreetingInput";
import Thanks from "./Thanks";
import { Route, Routes } from "react-router-dom";

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="App">
        <Container maxWidth="lg">
          <Stack direction={"row"} justifyContent="center" alignContent="center" padding={5}>
            <Typography variant="h4" align="center">
              80 Jahre
              <br />
              Maria Gehring
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"baseline"} justifyContent="center" paddingBottom={5}>
            <div className="img-ballons-translate">
              <img src="./ballons.png" className="img-ballons img-ballons-rotate" alt="Ballon" />
            </div>
            <div>
              <img src="./maria.jpg" className="img-maria" alt="Maria Gehring" />
            </div>
            <div className="img-ballons-translate">
              <img src="./ballons.png" className="img-ballons img-ballons-rotate2" alt="Ballon" />
            </div>
          </Stack>
          <Typography variant="h6" align="center">
              Feier am 10.04.2022
              <br />
              in Bergheim bei Augsburg
            </Typography>
          <Routes>
            <Route exact path="/" element={<GreetingInput />} />
            <Route path="/Thanks" element={<Thanks />} />
          </Routes>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default App;
